const idPortalApi = "62a5914b369817a999c77f98eed79c7db7161d7e";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  //urlApi: `http://ec2-54-232-59-57.sa-east-1.compute.amazonaws.com:8004/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "codo.ma.gov.br",
  NomePortal: "Codó",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 12, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://codo.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `#`,
  link_tiktok: `#`,
  link_twitter: `#`,
  link_threads: `#`,
  link_localize: `https://maps.app.goo.gl/vdHjXtuRndFvvu8p6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41833.7433010954!2d-43.89100321613651!3d-4.463387103297175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78bf16cf1e200a1%3A0x41ecdc5fb99aae3!2sCod%C3%B3%2C%20State%20of%20Maranh%C3%A3o%2C%2065400-000!5e0!3m2!1sen!2sbr!4v1741272884340!5m2!1sen!2sbr`,
};
