import "../../../styles/uniqueNews.css";
import React from "react";
import api from "../../../services/api";
import CarouselIMG from "../img/CarouselIMG";
import Loading from "../others/Loading";
import { imagemURL, link_mapa } from "../../../services/variables";
import styles from "../../../styles/module/news3.module.css";

export default class Seletivo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLast: [],
      dataFormat: "",
      renderLoading: true,
      multiImg: [],
    };
  }

  componentDidMount() {
    api
      .post(`/news/unique/${this.props.slugNewsSystem}`)
      .then((res) => {
        const result = res.data;
        if (result.err) {
          //this.setState({renderLoading: false});
          //this.setState({renderLoading: false});
        } else {
          console.log(result);
          this.setState({ newsLast: result.res, renderLoading: false });
        }
      })
      .catch((err) => console.log("erro, DB"));
  }

  render() {
    return (
      <>
        {this.state.renderLoading ? (
          <Loading />
        ) : (
          <div className={styles.container}>
            {this.state.newsLast.map((news, i) => (
              <div className={styles.newsItem} key={i}>
                <div className={`${styles.header}`}>
                  <h1 className={styles.title}>
                    SELEÇÃO SIMPLIFICADA INTERNA DE ESTUDANTES PARA <br />
                    OS CLUBINHOS
                    DE ROBÓTICA DE CODÓ-MA
                  </h1>
                </div>
                <div className={styles.imageWrapper}>
                  {news.IMG === "logo.png" ? null : (
                    <img
                      width={360}
                      alt="imagem da notícia"
                      src={imagemURL + news.IMG}
                    />
                  )}
                </div>
                {this.props.slugNewsSystem === "municipio-mapa" && (
                  <div className="container-mapa">
                    <iframe
                      src={link_mapa}
                      allowfullscreen=""
                      loading="lazy"
                      className="mapa-localizacao"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                )}
                <div className="section-unique-news">
                  <div className="cards-unique-news" key={i}>
                    {news.IMG == "logo.png" ? (
                      <></>
                    ) : (
                      <div>
                        <img
                          alt="imagem da notícia"
                          src={
                            news.IMG == "logo.png"
                              ? "/imagens/logo.png"
                              : imagemURL + news.IMG
                          }
                          className="card-unique-img"
                        />
                      </div>
                    )}
                    <div className="card-unique-txt">
                      <div className="title-unique-body"></div>
                      <div className="news-unique-body">
                        <p>
                          A Prefeitura Municipal de Codó, por meio da Secretaria
                          de Educação, abriu o processo de Seleção Simplificada
                          Interna de Estudantes para participar dos Clubinhos de
                          Robótica. Esta iniciativa tem como objetivo
                          proporcionar aos estudantes da rede pública de ensino
                          uma experiência educacional inovadora e de alta
                          qualidade, ampliando suas habilidades no campo da
                          robótica, tecnologia e programação.
                        </p>

                        <p>
                          A Prefeitura Municipal de Codó, por meio da Secretaria
                          de Educação, abriu o processo de Seleção Simplificada
                          Interna de Estudantes para participar dos Clubinhos de
                          Robótica. Esta iniciativa tem como objetivo
                          proporcionar aos estudantes da rede pública de ensino
                          uma experiência educacional inovadora e de alta
                          qualidade, ampliando suas habilidades no campo da
                          robótica, tecnologia e programação. Para mais
                          informações, os interessados podem entrar em contato
                          diretamente com a Secretaria de Educação de Codó ou
                          acessar os arquivos e links anexados nesta publicação.
                        </p>

                        <p>
                          <strong>Anexos:</strong> <br />
                          <ul>
                            <li>
                              <span>&#8226;</span> &nbsp;
                              <a
                                href="https://administracaopublica.com.br/downloads/other?n=06104863000195&p=anexo&a=anexo_aaef7f9b-1f93-47f4-baf4-912435c95342.pdf"
                                style={{ color: "blue" }}
                                target="blank"
                              >
                                Edital de Seleção Simplificada Interna
                              </a>
                            </li>
                            <li>
                              <span>&#8226;</span> &nbsp;
                              <a
                                href="https://administracaopublica.com.br/downloads/other?n=06104863000195&p=anexo&a=anexo_48305b7d-af41-4680-96f2-1b4435c763cf.pdf"
                                style={{ color: "blue" }}
                                target="blank"
                              >
                                Retificação 01
                              </a>
                            </li>

                            <li>
                              <span>&#8226;</span> &nbsp;
                              <a
                                href="https://administracaopublica.com.br/downloads/other?n=06104863000195&p=anexo&a=anexo_56488b79-7b58-4c24-af31-d46103a93829.pdf"
                                style={{ color: "blue" }}
                                target="blank"
                              >
                                Retificação 02
                              </a>
                            </li>
                            <li>
                              <span>&#8226;</span> &nbsp;
                              <a
                                href="https://semecti.codo.ma.gov.br/seletivos/index.php?class=LoginForm"
                                style={{ color: "blue" }}
                                target="blank"
                              >
                                Clique aqui para realizar sua inscrição
                              </a>
                            </li>
                          </ul>
                        </p>

                        <p>
                          {" "}
                          Fique atento aos prazos e não perca a oportunidade de
                          fazer parte dessa experiência inovadora no mundo da
                          robótica!
                        </p>
                      </div>
                    </div>
                  </div>
                  <CarouselIMG img={news.MULTIPLE_IMG} />
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}
